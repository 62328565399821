<template>
  <base-section id="get-orders" space="0" class="py-5">
    <base-info-card
      :title="$t('orders')"
      :subtitle="''"
      space="4"
      color="primary"
    >
      <base-loading v-if="loadingContent" />
      <ListOrders v-else :orders="orders" />
    </base-info-card>
  </base-section>
</template>

<script>
import { orderCtrl } from "@/controllers";
import { formatDateAndTime, getErrorPayloadForSnackbar } from "@/helpers";

export default {
  name: "GetOrdersSection",
  components: {
    ListOrders: () => import("@/components/orders/ListOrders.vue"),
  },
  data() {
    return {
      loadingContent: true,
      orders: [],
    };
  },
  mounted() {
    orderCtrl
      .getOrders()
      .then(({ orders }) => {
        this.orders = [...orders];
      })
      .catch((error) => {
        const errorPayload = getErrorPayloadForSnackbar(error);
        this.$store.commit("user/OPEN_SNACKBAR", errorPayload);
      })
      .finally(() => {
        this.loadingContent = false;
      });
  },
  methods: {
    formatDateAndTime,
    getColorStatus(status) {
      return status === "solicitado" ? "green" : "red";
    },
  },
};
</script>

<style lang="sass">
.v-image
  transition: all .3s ease-in-out

.startup-list-card
  &:hover
    h6
      transition: color .3s
      color: var(--v-primary-base)

    .v-image
      transform: scale(1.035)
</style>
